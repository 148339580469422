import React, { useState } from "react";
import { loginAuth } from "../api";
import Input from "../components/Input";

const Login = (props) => {
  const { onIsLogin } = props;

  const [form, setForm] = useState({ is_wisuda: true });
  const [errors, setErrors] = useState({});

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await loginAuth(form);
      if (res.data) {
        if (res.data.message === "success") {
          // Success
          let _token = res.data._token.split("|")[1];
          localStorage.setItem("_tokenPegawai", _token);
          window.location.reload();
          onIsLogin(_token);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      }
    }
  };

  return (
    <div className="container vh-100 d-flex justify-content-center align-items-center">
      <div className="row w-100 justify-content-center">
        <form
          onSubmit={handleLogin}
          className="col-md-10 col-sm-12 col-lg-6 col-xl-5 p-4 p-lg-5 rounded-3 shadow-lg text-center bg-white"
        >
          <img
            src="https://sim.alfaprima.id/logo.jpg"
            className="img-fluid w-25 mb-3"
            alt=""
          />
          <h4 className="mb-4">Login Wisuda - Alfa Prima</h4>
          <Input
            className="mb-2"
            name="username"
            placeholder="NIP Panitia Wisuda"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.username}
          />
          <Input
            className="mb-2"
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.password}
          />
          <button className="btn btn-primary-3 w-100">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
