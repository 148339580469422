import React, { useEffect } from "react";

const Undangan = () => {
  useEffect(() => {
    const countingDown = () => {
      // Set the date we're counting down to
      let countDownDate = new Date("Dec 12, 2022 08:00:00").getTime();

      // Update the count down every 1 second
      let x = setInterval(function () {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        document.getElementById("demo").innerHTML =
          "<h1>" +
          days +
          " : " +
          hours +
          " : " +
          minutes +
          " : " +
          seconds +
          "</h1>";
        document.getElementById("demo").innerHTML +=
          "<h4>Hari : Jam : Menit : Detik</h4>";
        document.getElementById("demo").innerHTML +=
          "<h4>Sebelum Acara Wisuda Dimulai.</h4>";

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          document.getElementById("demo").innerHTML = "Acara sudah dimulai";
        }
      }, 1000);
    };

    countingDown();
  }, []);

  return (
    <>
      <h1>Undangan Wisuda GGWP</h1>
      <p id="demo"></p>
    </>
  );
};

export default Undangan;
