import React from "react";
import { useRecoilValue } from "recoil";
import MiddlewarePegawai from "../../middleware/Pegawai";
import { titleState } from "../../storages";
import { userState } from "../../storages/auth";
import LayoutsTopbar from "./Topbar";

const LayoutsMain = (props) => {
  const { children } = props;

  const title = useRecoilValue(titleState);
  const user = useRecoilValue(userState);

  return (
    <MiddlewarePegawai>
      <LayoutsTopbar />
      <div className="container bg-white py-4 mt-5 pt-4 pt-lg-5">
        <span className="d-inline-block bg-primary text-white small rounded py-1 px-2 mt-1">
          {user.nama && (
            <span className="fw-medium">
              <i className="fa fa-fw fa-user text-warning"/> {user.nama.substr(0, 12)}
            </span>
          )}
        </span>
        <h4 className="card-header fw-semibold text-dark pt-4 pb-2 mb-3 text-center">
          {title}
        </h4>
        {children}
      </div>
      <div className="py-3">
        <div className="container my-auto">
          <div className="text-center my-auto text-secondary">
            Copyright © Alfa Prima 2020 - {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </MiddlewarePegawai>
  );
};

export const Wow = () => {
  return (
    <div style={{position: 'sticky', top: '12px'}} className="p-5 bg-danger"/>
  )
}

export default LayoutsMain;
