import React from 'react'

function TableNew(props) {

    const { tableResponsive = true, children, striped = true, hover = true } = props;
  
    return (
      <div className="pt-3">
        <div className="position-relative">
          <div className={`${tableResponsive && 'table-responsive'} mb-3`}>
            <table
              className={`table table-sm table-bordered ${hover && 'table-hover'} ${
                striped && "table-striped"
              }`}
            >
              {children}
            </table>
          </div>
        </div>
      </div>
    );
}

export const Thead = (props) => {
    const {children} = props
    return (
        <thead>{children}</thead>
    )
}

export const Tbody = (props) => {
    const {children} = props
    return (
        <tbody>{children}</tbody>
    )
}

export const Tr = (props) => {
    const {className, children} = props
    return (
        <tr className={`${className}`}>{children}</tr>
    )
}

export const Th = (props) => {
    const {className, style, children, colSpan, rowSpan} = props
    return (
        <th colSpan={colSpan} rowSpan={rowSpan} className={`py-1 bg-primary text-white border-bottom border-silver fw-normal ${className}`} style={style}>{children}</th>
    )
}

export const Td = (props) => {
    const {className, style, children, colSpan, rowSpan, paddingNone} = props
    return (
        <td colSpan={colSpan} rowSpan={rowSpan} className={`${paddingNone ? 'p-0' : 'py-1'} border-silver ${className}`} style={style}>{children}</td>
    )
}

export default TableNew