import React from "react";

const NoData = (props) => {
  const { title } = props;
  return (
    <p className="d-flex justify-content-center text-secondary align-items-center py-5 bg-light">
      {title ? title : "Tidak ada data tersedia."}
    </p>
  );
};

export default NoData;
