import React from "react";
import { Link, useHistory } from "react-router-dom";

const NoMatch = () => {
  const history = useHistory();
  return (
    <div>
      Ups, 404 Not Found.{" "}
      <Link to="#" onClick={history.goBack}>
        Back to site
      </Link>
    </div>
  );
};

export default NoMatch;
