import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { checkAuth, postLogout } from "../api";
import { saAlert } from "../helpers";
import Login from "../pages/Login";
import { semesterAjaranState, tahunAjaranState } from "../storages";
import { tokenState, userState } from "../storages/auth";

const MiddlewarePegawai = (props) => {
  const { children } = props;

  const [maintenance, setMaintenance] = useState(false);

  const [user, setUser] = useRecoilState(userState);
  const [token, setToken] = useRecoilState(tokenState);
  const setTahunAjaran = useSetRecoilState(tahunAjaranState);
  const setSemesterAjaran = useSetRecoilState(semesterAjaranState);

  // if exist token
  useEffect(() => {
    setToken(
      localStorage.getItem("_tokenPegawai")
        ? localStorage.getItem("_tokenPegawai")
        : ""
    );
  }, [setToken]);

  // Check token
  useEffect(() => {
    const handleLogout = async () => {
      try {
        const res = await postLogout(token);
        if (res.data === "success") {
          setToken("");
          setUser({});
          setTahunAjaran("");
          setSemesterAjaran("");
          localStorage.removeItem("_tokenPegawai");
          saAlert("success", "Berhasil logout !");
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    const checkUser = async () => {
      try {
        const res = await checkAuth(token);
        if (res.data && res.data.user && res.data.user.id) {
          setUser(res.data && res.data.user ? res.data.user : {});
          setTahunAjaran(
            res.data && res.data.tahun_ajaran ? res.data.tahun_ajaran : ""
          );
          setSemesterAjaran(
            res.data && res.data.semester_ajaran ? res.data.semester_ajaran : ""
          );
          console.log("Is-Pegawai");
        } else {
          handleLogout();
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            setToken("");
            setUser({});
            console.log("Is-Not-Pegawai");
          } else if (err.response.status === 503) {
            setMaintenance(true);
          }
        }
      }
    };
    if (token !== "") {
      checkUser();
    }
  }, [token, setUser, setToken, setTahunAjaran, setSemesterAjaran]);

  // Give alert if user already
  useEffect(() => {
    if (user.id) {
      setTimeout(() => {
        saAlert("success", "Berhasil login !");
      }, 1000);
    }
  }, [user.id]);

  const isLogin = (_token) => {
    localStorage.setItem("_tokenPegawai", _token);
    setToken(_token);
  };

  if (maintenance) {
    return "Maintenance";
  } else if (token !== "") {
    if (user.id) {
      return children;
    } else {
      return (
        <div className="bg-gradient-primary d-flex flex-column justify-content-center align-items-center vh-100 text-white p-3">
          <h6 className="mb-2">Authorization. . .</h6>
          <span className="loader-awesome-3 mb-3"></span>
        </div>
      );
    }
  } else {
    return <Login onIsLogin={(_token) => isLogin(_token)} />;
  }
};

export default MiddlewarePegawai;
