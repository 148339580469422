import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { postLogout } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import { tokenState } from "../../storages/auth";

const LayoutsTopbar = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const setUser = useSetRecoilState(tokenState);

  const handleLogout = () => {
    saConfirm("warning", "Yakin ingin logout ?").then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await postLogout(token);
          if (res.data === "success") {
            setToken("");
            setUser({});
            localStorage.removeItem("_tokenPegawai");
            saAlert("success", "Berhasil logout !");
          }
        } catch (err) {
          console.error(err.response);
        }
      }
    });
  };

  return (
    <>
      <div>
        <nav className="navbar bg-primary shadow-nice rounded-3 m-2 m-lg-3 fixed-top">
          <div className="container-fluid">
            <div>
              <Link
                className="navbar-brand text-white d-flex align-items-center p-0"
                to="/"
              >
                <img src="https://dev.alfaprima.id/assets/img/logo/AP-W.png" alt="" width={36} height={36} style={{marginTop: "1px"}}/>
                <span className="ms-1 fs-5 font-logo">WISUDA - ALFA PRIMA</span>
              </Link>
            </div>

            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-fw fa-bars link-warning py-1 fs-3" />
            </button>

            <div
              className="offcanvas offcanvas-end bg-light shadow-nice rounded-3 border-0"
              data-bs-scroll="false"
              data-bs-backdrop="true"
              tabIndex={-1}
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header bg-primary rounded-top-3 shadow-xl d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src="https://dev.alfaprima.id/assets/img/logo/AP-W.png" alt="" width={36} height={36} style={{marginTop: "2px"}}/>
                  <h5
                    className="ms-1 offcanvas-title text-white fs-5 font-logo"
                    id="offcanvasNavbarLabel"
                  >
                    SIDOS - ALFA PRIMA
                  </h5>
                </div>
                <i
                  className="fa fa-fw fa-close text-gray-400 fs-4 shadow-nice rounded-2 p-1"
                  type="button"
                  data-bs-dismiss="offcanvas"
                />
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1">
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/"
                    >
                      <i className="fa fa-fw fa-calendar-check fs-5 me-2" /> Absensi Wisuda
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/wisuda-list"
                    >
                      <i className="fa fa-fw fa-image-portrait fs-5 me-2" /> List Wisudawan
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <button
                      onClick={handleLogout}
                      className="nav-link btn btn-sm btn-danger-2 border-0 rounded-3 shadow mt-3 py-1 w-100"
                    >
                      <i className="align-middle fa fa-fw fa-power-off fs-5" /> Keluar Akun
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export const NewLabel = () => {
  return (
    <span className="bg-warning text-black fw-semibold px-2 rounded ms-1 text-sm">Baru</span>
  );
}

export default LayoutsTopbar;
