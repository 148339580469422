import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchingData, fetchWisuda, patchWisuda } from "../api";
import Input from "../components/Input";
import NoData from "../components/NoData";
import { saAlert, saConfirm } from "../helpers";
import { titleState } from "../storages";
import { tokenState } from "../storages/auth";

const Home = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const tagForm = useRef();

  useEffect(() => {
    setTitle("Absensi Wisuda");
  }, [setTitle]);

  const [items, setItems] = useState([]);
  const [form, setForm] = useState("");

  const [onFetching, setOnFetching] = useState(false);

  // Fetch By Form
  const fetchForm = useCallback(async () => {
    setOnFetching(true);
    try {
      const res = await fetchWisuda(
        "all",
        token,
        `search=${form}&jumlah=true&tahun=2024`
      );
      setItems(res.data);
      setOnFetching(false);
    } catch (err) {
      console.log(err);
    }
  }, [token, form]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  // Handle Button
  let timer = 0;
  const handleChange = (e) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setForm(e.target.value);
      fetchingData();
    }, 1000);
  };

  const handleReset = () => {
    tagForm.current && tagForm.current.reset();
    setForm("");
  };

  // Absen
  const handleAbsen = (id, data, status, type) => {
    saConfirm(
      "question",
      `${status === 1 ? `${type === "mhs" ? "Absen hadir pada wisudawan ini?" : type === "pen_1" ? "Hadirkan Pendamping 1 wisudawan ini?" : "Hadirkan Pendamping 2 wisudawan ini?"}` : "Pulangkan wisudawan ini?"}`
    ).then((res) => {
      if (res.isConfirmed) {
        patchWisuda(id, data, token)
          .then((res) => {
            if (res.data === "success") {
              fetchForm();
              saAlert(
                "success",
                // `${status === 1 ? "Wisudawan ini kini hadir" : "Wisudawan ini kini pulang"}`
                `${status === 1 ? `${type === "mhs" ? "Wisudawan ini kini hadir" : type === "pen_1" ? "Pendamping 1 kini hadir" : "Pendamping 2 kini hadir"}` : "Wisudawan ini kini pulang"}`
              );
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <>
      <div>
        <button
          style={{position: 'fixed', bottom: '20px', right: '12px', zIndex: '100'}}
          className="btn btn-dark text-white shadow-nice py-3"
          onClick={fetchForm}
          disabled={onFetching}
        >
          <i className="fa fa-sync me-2"></i>
          <span>{onFetching ? "Loading . . ." : "Reload"}</span>
        </button>

        <div className="row mb-3 bg-gradient-primary border-bottom-warning rounded-4 px-3 py-4 mx-0">
          <div className="col text-center text-danger-3 border-end border-silver-2">
            <h6 className="bg-primary-4 border border-silver-2 rounded-2 p-2">Belum Hadir</h6>
            <h3 className="mb-0">{items.jumlah && items.jumlah.belum}</h3>
          </div>
          <div className="col text-center text-white">
            <h6 className="bg-primary-4 border border-silver-2 rounded-2 p-2">Sudah Hadir</h6>
            <h3 className="mb-0">{items.jumlah && items.jumlah.hadir}</h3>
          </div>
          {/* <div className="col text-center border-primary text-success">
            <h5>Sudah Pulang</h5>
            <hr className="mt-0" />
            <h5>{items.jumlah && items.jumlah.pulang}</h5>
          </div> */}
        </div>
      </div>
      <form
        ref={tagForm}
        className="bg-light mb-3"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="row">
          <div className="col-lg-5">
            <Input className="mb-3 border-silver" placeholder="Search" onChange={handleChange} autoComplete="off" />
            <p className="px-2 small"><i className="fa fa-fw fa-circle-info"/> Search ini dapat anda gunakan untuk mencari nomer urut wisudawan dan nama wisudawan.</p>
            <div className="text-end text-lg-start">
              <button
                type="button"
                className="btn btn-dark rounded-2 mb-3 px-4"
                onClick={handleReset}
              >
                <i className="fa fa-fw fa-ban"></i>
                <span className="ms-2">Reset Search</span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="row mx-0">
        {items.wisuda && items.wisuda.length > 0 ? (
          items.wisuda.map((i, index) => {
            return (
              <div className="col-lg-4 px-2 mb-3" key={index}>
                <div className="bg-gradient-primary border-bottom-warning rounded-4 p-3 text-secondary shadow-nice">
                  <div className="row mb-3">
                    <div className="col-4 text-white mb-3">
                      <p className="mb-1">No.</p>
                      <div
                        className={`${i.absensi === null ? "bg-primary-4" : "bg-success"
                          } position-relative text-white border border-dark rounded shadow-nice py-5 px-3`}
                      >
                        <h1 style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} className="mb-0">{i.nomor}</h1>
                      </div>
                    </div>

                    <div className="col-8">
                      <div className="mb-2">
                        <p className="text-gray-500 mb-0">NIM :</p>
                        <p className="text-white fw-medium mb-0">{i.mhs && i.mhs.nim}</p>
                      </div>
                      <div className="mb-2">
                        <p className="text-gray-500 mb-0">Nama :</p>
                        <p className="text-white fw-medium mb-0">{i.mhs && i.mhs.nama}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/* Mhs */}
                    <div className="col-lg-12 mb-2">
                      {i.absensi === null && (
                        <button
                          className="btn btn-warning rounded-3 fw-medium w-100"
                          type="button"
                          onClick={() => handleAbsen(i.id, { absensi: 1 }, 1, "mhs")}
                        >
                          Absen Mahasiswa
                        </button>
                      )}
                      {/* {i.absensi === 1 && (
                    <div className="d-flex justify-content-around">
                      <span className="text-info">Runing</span>
                      <span
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={() => handleAbsen(i.id, { absensi: 2 }, 2)}
                      >
                        Absen Pulang
                      </span>
                    </div>
                    )} */}
                      {i.absensi === 1 && (
                        <button
                          className="btn btn-success rounded-3 w-100"
                          type="button"
                          disabled
                        >
                          <i className="fa fa-fw fa-check" /> Sudah Hadir
                        </button>
                      )}
                    </div>
                    {/* End Mhs */}

                    {/* Pendamping 1 */}
                    <div className="col-lg-12 mb-2">
                      {i.absensi_1 === null && (
                        <div className="d-flex justify-content-around">
                          <span
                            className="btn btn-primary-2 text-white w-100"
                            type="button"
                            onClick={() => handleAbsen(i.id, { absensi_1: 1 }, 1, "pen_1")}
                          >
                            Absen Pendamping 1
                          </span>
                        </div>
                      )}
                      {i.absensi_1 === 1 && (
                        <button
                          className="btn btn-success rounded-3 w-100"
                          type="button"
                          disabled
                        >
                          <i className="fa fa-fw fa-check" /> Pendamping 1 Hadir
                        </button>
                      )}
                    </div>
                    {/* End Pendamping 1 */}

                    {/* Pendamping 2 */}
                    <div className="col-lg-12 mb-2">
                      {i.absensi_2 === null && (
                        <div className="d-flex justify-content-around">
                          <span
                            className="btn btn-primary-2 text-white w-100"
                            type="button"
                            onClick={() => handleAbsen(i.id, { absensi_2: 1 }, 1, "pen_2")}
                          >
                            Absen Pendamping 2
                          </span>
                        </div>
                      )}
                      {i.absensi_2 === 1 && (
                        <button
                          className="btn btn-success rounded-3 w-100"
                          type="button"
                          disabled
                        >
                          <i className="fa fa-fw fa-check" /> Pendamping 2 Hadir
                        </button>
                      )}
                    </div>
                    {/* End Pendamping 2 */}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};

export default Home;
