import config from "axios";
import { getOrigin, saToast } from "../helpers";

const axios = config.create({
  baseURL: getOrigin(),
});

export const fetchingData = (
  icon = "success",
  title = "Loading, please wait !"
) => {
  saToast(icon, title);
};

// Bearer Token
export const headers = (token) => {
  return { headers: { Authorization: `Bearer ${token}` } };
};

// Login
export const loginAuth = (data) => {
  fetchingData();
  return axios.post("/api/login", data);
};

// Checking
export const checkAuth = (token) => {
  return axios.get("/api/check", headers(token));
};

// Logout
export const postLogout = (token) => {
  fetchingData();
  return axios.post(`/api/logout`, null, headers(token));
};

// Wisuda
// index
export const fetchWisuda = (page = 1, token, custom = "") => {
  return axios.get(`/api/wisuda?page=${page}&${custom}`, headers(token));
};
// update
export const patchWisuda = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/wisuda/${id}`, data, headers(token));
};