import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchingData, fetchWisuda } from "../../api";
import Table, { Tbody, Td, Th, Thead, Tr } from "../../components/Table";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";

const WisudaList = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    setTitle("List Wisuda");
  }, [setTitle]);

  const [items, setItems] = useState([]);
  const [status, setStatus] = useState("null");

  const [onFetching, setOnFetching] = useState(false);

  const fetchData = useCallback(async () => {
    setOnFetching(true);
    try {
      const res = await fetchWisuda(
        "all",
        token,
        `absensi=${status}&jumlah=true&tahun=2024`
      );
      setItems(res.data.wisuda);
      console.log(res.data.wisuda.length);
      setOnFetching(false);
    } catch (err) {
      console.log(err);
    }
  }, [token, status]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  return (
    <div className="container">
      <button
        style={{ position: 'fixed', bottom: '20px', right: '12px', zIndex: '100' }}
        className="btn btn-dark text-white shadow-nice py-3"
        onClick={fetchData}
        disabled={onFetching}
      >
        <i className="fa fa-sync me-2"></i>
        <span>{onFetching ? "Loading . . ." : "Reload"}</span>
      </button>
      <select
        className="form-select w-md-50 m-auto mb-3"
        value={status}
        onChange={(e) => {
          setItems([]);
          setStatus(e.target.value);
          fetchingData();
        }}
      >
        <option value="null">Belum Hadir</option>
        <option value="1">Sudah Hadir</option>
        {/* <option value="2">Sudah Pulang</option> */}
      </select>
      {/* <div className="text-center mb-3">
        <button
          className="btn btn-sm btn-info text-white"
          onClick={fetchData}
          disabled={onFetching}
        >
          <i className="fa fa-sync me-2"></i>
          <span>{onFetching ? "Loading . . ." : "Refresh Kehadiran"}</span>
        </button>
      </div> */}
      <div className="">
        {status === "null" && (
          <h6 className="mb-3 text-black">Belum Hadir : {items.length} orang</h6>
        )}
        {status === "1" && (
          <h6 className="mb-3 text-black">Sudah Hadir : {items.length} orang</h6>
        )}
        {/* {status === "2" && (
          <h6 className="mb-3 text-black">Sudah Pulang : {items.length} orang</h6>
        )} */}
      </div>

      <Table>
        <Thead>
          <Tr className="text-center">
            <Th>No</Th>
            <Th>NIM</Th>
            <Th>Nama</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.length > 0 &&
            items.map((i, index) => {
              return (
                <Tr
                  key={index}
                  className={
                    status === "null"
                      ? "text-danger"
                      : status === "1"
                      ? "text-info"
                      : status === "2" && "text-success"
                  }
                >
                  <Td className="text-center align-middle">{index + 1}</Td>
                  <Td className="text-center align-middle">
                    {i.mhs && i.mhs.nim}
                  </Td>
                  <Td className="align-middle">{i.mhs && i.mhs.nama}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </div>
  );
};

export default WisudaList;
