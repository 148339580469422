import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LayoutsMain from "../components/layouts/Main";
import Home from "../pages/Home";
import NoMatch from "../pages/NoMatch";
import Undangan from "../pages/Undangan";
import WisudaList from "../pages/wisuda/List";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LayoutsMain>
            <Home />
          </LayoutsMain>
        </Route>
        <Route path="/wisuda-list">
          <LayoutsMain>
            <WisudaList />
          </LayoutsMain>
        </Route>
        <Route path="/undangan2021">
          <Undangan />
        </Route>
        {/* 404 */}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
